import React, { useState, useEffect } from "react";

function GetinTouch() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    inquiryType: "", // Hidden field, value set from the page path
    message: "",
  });

  const [errors, setErrors] = useState({});

  // Set inquiryType based on the page path
  useEffect(() => {
    const path = window.location.pathname; // Get the current page path
    setFormData((prevData) => ({
      ...prevData,
      inquiryType: path, // Assign the page path to inquiryType
    }));
  }, []);

  const validate = () => {
    const newErrors = {};
    const phoneRegex = /^[0-9]{10}$/; // Basic validation for phone number (10 digits)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email validation

    if (!formData.name) {
      newErrors.name = "Name is required.";
    }

    if (!formData.email || !emailRegex.test(formData.email)) {
      newErrors.email = "Valid email is required.";
    }

    if (!formData.phone || !phoneRegex.test(formData.phone)) {
      newErrors.phone = "Valid phone number is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if there are no errors
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return; // Validate before sending

    try {
      const response = await fetch(
        "https://epe-process.psmprojects.net/contact-form.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();
      if (response.ok) {
        // Show success alert
        alert("Thank you for contacting us!");

        // Clear the form inputs
        setFormData({
          name: "",
          email: "",
          phone: "",
          subject: "",
          inquiryType: window.location.pathname, // Reset inquiryType to the current path
          message: "",
        });
      } else {
        // Handle error (e.g., show an error message)
        console.error("Form submission error:", result);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  return (
    <div>
      <div className="get-in-touch-bg">
        <div className="get-in-touch-form">
          <div className="heading-2">
            <h4>
              <span>Contact Us</span>
            </h4>
            <h3 className="blue-color">Get in Touch</h3>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="text-field-1"
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && <span className="error">{errors.name}</span>}

              <input
                type="text"
                name="email"
                placeholder="E mail"
                className="text-field-1"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <span className="error">{errors.email}</span>}

              <input
                type="text"
                name="phone"
                placeholder="Contact Number"
                className="text-field-1"
                value={formData.phone}
                onChange={handleChange}
              />
              {errors.phone && <span className="error">{errors.phone}</span>}

              <input
                type="text"
                name="subject"
                placeholder="Subject"
                className="text-field-1"
                value={formData.subject}
                onChange={handleChange}
              />

              <textarea
                name="message"
                placeholder="Message"
                className="text-area-1"
                value={formData.message}
                onChange={handleChange}
              ></textarea>

              {/* Hidden field for inquiryType */}
              <input
                type="hidden"
                name="inquiryType"
                value={formData.inquiryType}
              />

              <button type="submit" className="button-1">
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GetinTouch;
