import React, { useState, useEffect } from "react";
import { Route, Routes, Link } from "react-router-dom";
import "./styles/verticaltabs.css";
import ImageDetail from "./images/CO-img-1.png";

function Filter() {
  const [activeTab, setActiveTab] = useState(0);
  const [isAccordion, setIsAccordion] = useState(false);

  const tabs = ["Tab 1", "Tab 2", "Tab 3", "Tab 4"];
  const subHeadings = [
    "Subheading for Tab 1",
    "Subheading for Tab 2",
    "Subheading for Tab 3",
    "Subheading for Tab 4",
  ];

  const content = [
    [
      {
        id: 1,
        name: "Image 1",
        imgSrc: "https://via.placeholder.com/100",
        description: "This is a description for Image 1",
      },
      {
        id: 2,
        name: "Image 2",
        imgSrc: "https://via.placeholder.com/100",
        description: "This is a description for Image 2",
      },
      {
        id: 3,
        name: "Image 3",
        imgSrc: "https://via.placeholder.com/100",
        description: "This is a description for Image 3",
      },
      {
        id: 4,
        name: "Image 4",
        imgSrc: "https://via.placeholder.com/100",
        description: "This is a description for Image 4",
      },
    ],
    [
      {
        id: 5,
        name: "Image 5",
        imgSrc: "https://via.placeholder.com/100",
        description: "This is a description for Image 5",
      },
      {
        id: 6,
        name: "Image 6",
        imgSrc: "https://via.placeholder.com/100",
        description: "This is a description for Image 6",
      },
      {
        id: 7,
        name: "Image 7",
        imgSrc: "https://via.placeholder.com/100",
        description: "This is a description for Image 7",
      },
      {
        id: 8,
        name: "Image 8",
        imgSrc: "https://via.placeholder.com/100",
        description: "This is a description for Image 8",
      },
    ],
    [
      {
        id: 9,
        name: "Image 9",
        imgSrc: "https://via.placeholder.com/100",
        description: "This is a description for Image 9",
      },
      {
        id: 10,
        name: "Image 10",
        imgSrc: "https://via.placeholder.com/100",
        description: "This is a description for Image 10",
      },
      {
        id: 11,
        name: "Image 11",
        imgSrc: "https://via.placeholder.com/100",
        description: "This is a description for Image 11",
      },
      {
        id: 12,
        name: "Image 12",
        imgSrc: "https://via.placeholder.com/100",
        description: "This is a description for Image 12",
      },
    ],
    [
      {
        id: 13,
        name: "Image 13",
        imgSrc: "https://via.placeholder.com/100",
        description: "This is a description for Image 13",
      },
      {
        id: 14,
        name: "Image 14",
        imgSrc: "https://via.placeholder.com/100",
        description: "This is a description for Image 14",
      },
      {
        id: 15,
        name: "Image 15",
        imgSrc: "https://via.placeholder.com/100",
        description: "This is a description for Image 15",
      },
      {
        id: 16,
        name: "Image 16",
        imgSrc: "https://via.placeholder.com/100",
        description: "This is a description for Image 16",
      },
    ],
  ];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsAccordion(true);
      } else {
        setIsAccordion(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="tab-container">
      <Routes>
        <Route
          path="/"
          element={
            <>
              {!isAccordion ? (
                <>
                  <div className="vertical-tabs">
                    {tabs.map((tab, index) => (
                      <button
                        key={index}
                        className={`tab-button ${
                          activeTab === index ? "active" : ""
                        }`}
                        onClick={() => setActiveTab(index)}
                      >
                        {tab}
                      </button>
                    ))}
                  </div>
                  <div className="tab-content">
                    <h1>{tabs[activeTab]}</h1>
                    <h4>{subHeadings[activeTab]}</h4>
                    <div className="thumbnails">
                      {content[activeTab].map((item) => (
                        <div key={item.id} className="thumbnail-item">
                          <Link to={`/image/${item.id}`}>
                            <img src={item.imgSrc} alt={item.name} />
                            <p>{item.name}</p>
                          </Link>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ) : (
                <div className="accordion">
                  {tabs.map((tab, index) => (
                    <div key={index} className="accordion-item">
                      <button
                        className={`accordion-button ${
                          activeTab === index ? "active" : ""
                        }`}
                        onClick={() =>
                          setActiveTab(activeTab === index ? -1 : index)
                        }
                      >
                        {tab}
                      </button>
                      {activeTab === index && (
                        <div className="accordion-content">
                          <h4>{subHeadings[activeTab]}</h4>
                          <div className="thumbnails">
                            {content[activeTab].map((item) => (
                              <div key={item.id} className="thumbnail-item">
                                <Link to={`/image/${item.id}`}>
                                  <img src={item.imgSrc} alt={item.name} />
                                  <p>{item.name}</p>
                                </Link>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </>
          }
        />
        <Route path="/image/:id" element={<ImageDetail content={content} />} />
      </Routes>
    </div>
  );
}
export default Filter;
