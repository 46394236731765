import React from "react";
import Awardsimg from "./images/Awards-img-1.png";
import Awardsimg2 from "./images/Awards-img-2.png";

import GetinTouch from "./GetinTouch";

function Certifications() {
  return (
    <div>
      <div className="inner-banner">
        <div className="wrapper">
          <h1>Awards & Achievement</h1>
          <h6>
            Every bit we grow is a testament to the trust and appreciation from
            our customers.
          </h6>
        </div>
      </div>

      <div className="Certifications-sec2-bg ">
        <div className="wrapper">
          {/* <div className="AwardsAchievement-wrap">
            <div className="AwardsAchievement-1">
              <div className="AwardsAchievement-box ">
                <img src={Awardsimg} alt={Awardsimg} />
                <p>
                  National Award-2006 for Best Entrepreneur instituted by the
                  Ministry of Small & Medium Enterprises, Govt. of India and
                  presented by Dr.Man Mohan Singh, Hon’ble Prime Minister of
                  India.
                </p>
              </div>
            </div>
            <div className="AwardsAchievement-1">
              <div className="AwardsAchievement-box ">
                <img src={Awardsimg2} alt={Awardsimg2} />
                <p>
                  National Award-2006 for Research & Development Efforts in
                  Small Scale Industries instituted by the Ministry of Small &
                  Medium Enterprises, Govt. of India and presented by Mr.Mahavir
                  Prasad Hon’ble Minister for MSME
                </p>
              </div>
            </div>
          </div> */}

          <div className="AwardsAchievement2-wrap">
            <div className="AwardsAchievement-2">
              <img src={Awardsimg} alt="Best Entrepreneur Award 2006" />
              <p>
                National Award-2006 for Best Entrepreneur instituted by the
                Ministry of Small & Medium Enterprises, Govt. of India and
                presented by Dr.Man Mohan Singh, Hon’ble Prime Minister of
                India.
              </p>
            </div>
            <div className="AwardsAchievement-2">
              <img src={Awardsimg2} alt="R&D Award 2006" />
              <p>
                National Award-2006 for Research & Development Efforts in Small
                Scale Industries instituted by the Ministry of Small & Medium
                Enterprises, Govt. of India and presented by Mr.Mahavir Prasad
                Hon’ble Minister for MSME
              </p>
            </div>

            <div className="AwardsAchievement-2">
              <p>
                Navratna Trophy & Gold Medal from Dr. Shankar Dayal Sharma, the
                then President of India.
              </p>
            </div>
            <div className="AwardsAchievement-2">
              <p>
                Bharat Ratna Dr.M.Visvesvaraya Industrial Award for Best
                Performance in Import Substitution-2001 from All India
                Manufacturers’ Organization (AIMO).
              </p>
            </div>
            <div className="AwardsAchievement-2">
              <p>
                Best Vendor Award for the Year 2000-2001 from Bharat Heavy
                Electricals Ltd, Hyderabad.
              </p>
            </div>
            <div className="AwardsAchievement-2">
              <p>
                Best New Product Development Award for the year 2002 from
                Confederation of Indian Industry (CII).
              </p>
            </div>
            <div className="AwardsAchievement-2">
              <p>
                Best Technology Development in R&D Award for the year 2002-2003
                from Federation of Andhra Pradesh Chambers of Commerce &
                Industry (FAPCCI)
              </p>
            </div>
            <div className="AwardsAchievement-2">
              <p>
                Best Entrepreneur Award - 2003 for SSI by The Confederation of
                Indian Industry (CII)
              </p>
            </div>
            <div className="AwardsAchievement-2">
              <p>
                Best Technology Development in R&D Award 2002-2003 by The
                Federation of Andhra Pradesh Chambers of Commerce & Industry
                (FAPCCI)
              </p>
            </div>
            <div className="AwardsAchievement-2">
              <p>National Quality Award</p>
            </div>
            <div className="AwardsAchievement-2">
              <p>
                Bharat Ratna Dr.M.Visvesvaraya Industrial Award for BEST
                PERFORMANCE IN IMPORT SUBSTITUTION from All India Manufacturers'
                Organization.
              </p>
            </div>
            <div className="AwardsAchievement-2">
              <p>
                BEST VENDOR AWARD for the year 2000-01 from Bharat Heavy
                Electricals Ltd (BHEL).
              </p>
            </div>
            <div className="AwardsAchievement-2">
              <p>
                CITD Gaurav Udyog Vibhushan Award of the Millenium-2000 from
                Council for Industrial & Trade Development (India).
              </p>
            </div>
            <div className="AwardsAchievement-2">
              <p>
                Independence Gaurav Award for Industrial Promotion from Council
                for Industrial & Trade Development
              </p>
            </div>
            <div className="AwardsAchievement-2">
              <p>Arch of Excellence Award</p>
            </div>

            <div className="AwardsAchievement-2">
              <p>
                Navratna Trophy & Gold Medal from Dr.Shankar Dayal Sharma,
                President of India, for Quality, Innovation & Import
                Substitution.
              </p>
            </div>

            <div className="AwardsAchievement-2">
              <p>
                Rajiv Gandhi National Integration Award for Quality & Innovative
                Products.
              </p>
            </div>
            <div className="AwardsAchievement-2">
              <p>
                Indira Gandhi National Unity Award for Quality & Innovative
                Products.
              </p>
            </div>

            <div className="AwardsAchievement-2">
              <p>
                Gold Medal & Trophy from the Minister of Finance, Govt. of
                India.
              </p>
            </div>
          </div>
        </div>
      </div>

      <GetinTouch />
    </div>
  );
}

export default Certifications;
