// src/components/CareerDetailsTwo.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ContactPatch3 from "./images/Awards-img-2.png";
import Timestamp from "react-timestamp";
import DOMPurify from "dompurify";

const SeparateCareerDetailsTwo = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [qualification, setQualification] = useState("");
  const [experience, setExperience] = useState("");
  const [jobCategory, setJobCategory] = useState("");
  const [address, setAddress] = useState("");
  const [otherinformation, setOtherinformation] = useState("");
  const [jobRole, setJobRole] = useState("");
  const [resume, setResume] = useState(null);
  const [careerJobId, setJobId] = useState("");
  const [job, setJob] = useState(null);
  const [errors, setErrors] = useState({});

  const handleChange = (e, name) => {
    switch (name) {
      case "fname":
        setFname(e.target.value);
        break;
      case "lname":
        setLname(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "phone":
        setPhone(e.target.value);
        break;
      case "qualification":
        setQualification(e.target.value);
        break;
      case "experience":
        setExperience(e.target.value);
        break;
      case "address":
        setAddress(e.target.value);
        break;
      case "otherinformation":
        setOtherinformation(e.target.value);
        break;
      case "jobCategory":
        setJobCategory(e.target.value); // Update jobCategory state on change
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Required fields validation
    if (!fname) newErrors.fname = "First Name is required.";
    if (!lname) newErrors.lname = "Last Name is required.";
    if (!email) newErrors.email = "Email is required.";
    if (!phone) newErrors.phone = "Phone Number is required.";
    if (!qualification) newErrors.qualification = "Qualification is required.";
    if (!experience) newErrors.experience = "Experience is required.";
    if (!jobCategory) newErrors.jobCategory = "Job Category is required.";
    if (!address) newErrors.address = "Address is required.";
    if (!resume) newErrors.resume = "Resume upload is required.";

    // Email format validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailPattern.test(email)) {
      newErrors.email = "Invalid email format.";
    }

    // Phone number format validation (example: allow digits only, adjust as needed)
    const phonePattern = /^\d{10}$/; // Assuming 10 digits for phone
    if (phone && !phonePattern.test(phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Returns true if there are no errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Stop submission if validation fails
    }

    const uploadFile = async () => {
      try {
        const formData = new FormData();
        const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
        const fileWithTimestamp = new File(
          [resume],
          `${timestamp}_${resume.name}`,
          { type: resume.type }
        );

        formData.append("resumefile", fileWithTimestamp);
        formData.append("job_id", careerJobId);

        const response = await fetch(
          "https://epe-process.psmprojects.net/career-file-upload.php",
          {
            method: "POST",
            body: formData,
          }
        );

        if (response.ok) {
          const data = await response.json();
          console.log("File Upload Response:", data);
          if (data && data.error) {
            console.error("Server Error:", data.error);
            alert(`Upload Error: ${data.error}`);
            return null;
          } else {
            console.log("Uploaded file name:", data.filename);
            return data.filename;
          }
        } else {
          const errorText = await response.text();
          console.error("Server response:", errorText);
          // alert(`Upload failed: ${errorText}`);
          throw new Error("Failed to upload file");
        }
      } catch (error) {
        console.error("Upload error:", error);
        // alert("An error occurred while uploading the file. Please try again.");
        return null;
      }
    };

    const filename = await uploadFile();

    if (!filename) {
      alert("File upload failed. Please try again.");
      return;
    }

    const dataObj = {
      fname,
      lname,
      email,
      phone,
      qualification,
      experience,
      job_category: jobCategory,
      address,
      message: otherinformation,
      job_id: careerJobId,
      resumefile: filename,
    };
    console.log("dataObj", dataObj);

    const postData = async () => {
      try {
        const response = await fetch(
          "https://epe-process.psmprojects.net/career-form.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataObj),
          }
        );

        if (response.ok) {
          const data1 = await response.json();
          console.log("Response from post", data1);
          alert("Form submitted successfully!");
        } else {
          const errorText = await response.text();
          console.error("Server response:", errorText);
          // alert(`Form submission failed: ${errorText}`);
          throw new Error("Failed to submit form");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // alert("An error occurred while submitting the form. Please try again.");
      }
    };

    await postData();
  };

  const onChangeFileUpload = (e) => {
    const file = e.target.files[0];
    setResume(file);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const { id } = useParams();
  const jobId = parseInt(id);

  useEffect(() => {
    const fetchCarrerData = async () => {
      try {
        const response = await fetch(
          `https://epe-process.psmprojects.net/careerdetail.php/${jobId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jobData = await response.json();
        if (jobData) {
          setJob(jobData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchCarrerData();
  }, [jobId]);

  useEffect(() => {
    if (job) {
      setJobRole(job.job_title);
      setJobId(job.id);
      setJobCategory(job.job_category); // Set jobCategory from fetched job data
    }
  }, [job]);

  if (!job) {
    return <div>Job not found</div>;
  }

  return (
    <div>
      <div className="inner-banner">
        <div className="wrapper">
          <br />
        </div>
      </div>
      <div className="inner-page-bg-1">
        <div className="wrapper">
          <div className="career-inner-wrap">
            <h1></h1>
            <div className="career-l-1">
              <h4>
                <span>JOB TITLE:</span> {job.job_title}
              </h4>
            </div>
            <div className="career-l-1">
              <h4>Company:</h4>
              <p>{job.company_name}</p>
            </div>
            <div className="career-l-1">
              <h4>Location:</h4>
              <p>{job.location}</p>
            </div>

            <div className="career-l-1">
              <h4>RESPONSIBILITIES:</h4>
              {/* <p>{job.description}</p> */}
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(job.description),
                }}
              />
            </div>

            <div className="career-l-1">
              <h4>REQUIREMENTS:</h4>
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(job.requirements),
                }}
              />
              {/* <p>{job.requirements}</p> */}
            </div>
          </div>
          <div className="bullet-1">
            <ul>
              {Array.isArray(job.requirements) &&
                job.requirements.map((requirement, index) => (
                  <li key={index}>{requirement}</li>
                ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="form-styles">
        <div className="form-sec-main">
          <div className="wrapper">
            <h1>APPLY HERE</h1>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              <div className="form-sec-1">
                <b>First Name</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Enter Your First Name"
                  value={fname}
                  name="fname"
                  id="fname"
                  onChange={(e) => handleChange(e, "fname")}
                />
                {errors.fname && <span className="error">{errors.fname}</span>}
              </div>
              <div className="form-sec-1">
                <b>Last Name</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Enter Your Last Name"
                  value={lname}
                  name="lname"
                  id="lname"
                  onChange={(e) => handleChange(e, "lname")}
                />
                {errors.lname && <span className="error">{errors.lname}</span>}
              </div>
              <div className="form-sec-1">
                <b>Email Id</b>
                <input
                  className="text-1"
                  placeholder="Enter Your Email*"
                  type="email"
                  value={email}
                  name="email"
                  id="email"
                  onChange={(e) => handleChange(e, "email")}
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>
              <div className="form-sec-1">
                <b>Phone Number</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Your Phone No.*"
                  value={phone}
                  name="phone"
                  id="phone"
                  onChange={(e) => handleChange(e, "phone")}
                />
                {errors.phone && <span className="error">{errors.phone}</span>}
              </div>
              <div className="form-sec-1">
                <b>Qualification</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Your Qualification"
                  value={qualification}
                  name="qualification"
                  id="qualification"
                  onChange={(e) => handleChange(e, "qualification")}
                />
                {errors.qualification && (
                  <span className="error">{errors.qualification}</span>
                )}
              </div>
              <div className="form-sec-1">
                <b>Experience</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Your Experience"
                  value={experience}
                  name="experience"
                  id="experience"
                  onChange={(e) => handleChange(e, "experience")}
                />
                {errors.experience && (
                  <span className="error">{errors.experience}</span>
                )}
              </div>
              <div className="form-sec-1">
                <b>Job Category</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Enter Job Category"
                  value={jobCategory} // Bind to jobCategory state
                  name="jobCategory"
                  id="jobCategory"
                  onChange={(e) => handleChange(e, "jobCategory")} // Update jobCategory on change
                />
                {errors.jobCategory && (
                  <span className="error">{errors.jobCategory}</span>
                )}
              </div>
              <div className="form-sec-1">
                <b>Address</b>
                <input
                  className="text-1"
                  type="text"
                  placeholder="Your Address"
                  value={address}
                  name="address"
                  id="address"
                  onChange={(e) => handleChange(e, "address")}
                />
                {errors.address && (
                  <span className="error">{errors.address}</span>
                )}
              </div>
              <div className="form-sec-1 w-100">
                <b>Other Information</b>
                <textarea
                  className="textarea-1"
                  placeholder="Message"
                  value={otherinformation}
                  name="otherinformation"
                  id="otherinformation"
                  onChange={(e) => handleChange(e, "otherinformation")}
                />
              </div>

              <div className="form-sec-1 w-100">
                <b>Upload Resume</b>
                <input
                  className="text-2"
                  type="file"
                  name="resume"
                  id="resume"
                  onChange={onChangeFileUpload}
                />
                {errors.resume && (
                  <span className="error">{errors.resume}</span>
                )}
              </div>

              <div className="form-sec-1 w-100">
                <button type="submit" value="Submit" className="button-1">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeparateCareerDetailsTwo;
