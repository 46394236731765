import React from "react";
import { useLocation, Link } from "react-router-dom";

const ImageDetail = ({ content }) => {
  const location = useLocation();

  // Extract the image ID from the URL path (e.g., /image/1)
  const pathParts = location.pathname.replace(/\/$/, "").split("/");
  const imageId = parseInt(pathParts[pathParts.length - 1], 10);

  console.log("Extracted Image ID:", imageId); // Debug: log the imageId
  console.log("Content Array:", content); // Debug: log the content array

  // Find the image by its ID in the content array
  const image = content
    .reduce((acc, tab) => acc.concat(tab), [])
    .find((img) => img.id === imageId);

  if (!image) {
    console.log(`Image with ID ${imageId} not found`);
  }

  return (
    <div className="image-detail">
      {image ? (
        <>
          <h2>{image.name}</h2>
          <img src={image.imgSrc} alt={image.name} />
          <p>{image.description}</p>
          <Link to="/">Go back</Link>
        </>
      ) : (
        <p>Image not found</p>
      )}
    </div>
  );
};

export default ImageDetail;
