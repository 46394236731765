import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const images = [
  require("../images/property-slider-1.png"),
  require("../images/property-slider-2.png"),
  require("../images/property-slider-3.png"),
  require("../images/property-slider-4.png"),
  require("../images/property-slider-5.png"),
  require("../images/property-slider-6.png"),
  require("../images/property-slider-8.png"),
];

const SliderOne = () => {
  const settings = {
    draggable: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    dots: false,
    fade: true, // Fades between slides
    speed: 500,
    infinite: true,
    cssEase: "ease-in-out",
    touchThreshold: 100,
  };

  return (
    <div className="slideshow">
      <Slider {...settings}>
        {images.map((src, i) => (
          <div key={i}>
            <img src={src} alt={`Slide ${i + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderOne;
