import React from "react";
import EnvironmentManagement from "./images/EnvironmentManagement-img-1.png";
import { Link } from "react-router-dom";
import GetinTouch from "./GetinTouch";
import c1 from "./images/c-1.svg";
import c2 from "./images/c-2.svg";
import c3 from "./images/c-3.svg";
import c4 from "./images/c-4.svg";
import c5 from "./images/c-5.svg";
import download from "./images/download.svg";

const certificationsData = [
  {
    id: 1,
    imgSrc: "../images/c-1.svg", // Direct path to the first certification image
    title: "ISO-9001:2015",
    link: "./images/certifications/iso-9001-2015.pdf",
  },
  {
    id: 2,
    imgSrc: "../images/c-2.svg", // Direct path to the second certification image
    title: "ISO-14001:2015",
    link: "./images/certifications/iso-14001-2015.pdf",
  },
  {
    id: 3,
    imgSrc: "../images/c-3.svg", // Direct path to the third certification image
    title: "ISO-45001:2018",
    link: "./images/certifications/iso-45001-2018.pdf",
  },
  {
    id: 4,
    imgSrc: "../images/CE.svg", // Direct path to the first certification image
    title: "CE/PED",
    link: "./images/certifications/ce-ped.pdf",
  },
  {
    id: 5,
    imgSrc: "../images/ASME.svg", // Direct path to the second certification image
    title: "ASME U Stamp",
    link: "./images/certifications/asme-u-stamp.pdf",
  },
  {
    id: 6,
    imgSrc: "../images/c-6.svg", // Direct path to the third certification image
    title: "NB Stamp",
    link: "./images/certifications/nb-stamp.pdf",
  },
  {
    id: 7,
    imgSrc: "../images/c-7.svg", // Direct path to the third certification image
    title: "DOSH",
    link: "./images/certifications/dosh.pdf",
  },
  {
    id: 8,
    imgSrc: "../images/c-8.svg", // Direct path to the third certification image
    title: "DNV",
    link: "./images/certifications/dnv.pdf",
  },
  {
    id: 9,
    imgSrc: "../images/ABS.svg", // Direct path to the third certification image
    title: "ABS",
    link: "./images/certifications/abs.pdf",
  },
  {
    id: 10,
    imgSrc: "../images/c-10.svg", // Direct path to the third certification image
    title: "R & D",
    link: "./images/certifications/rnd.pdf",
  },

  {
    id: 11,
    imgSrc: "../images/EAC.svg", // Direct path to the third certification image
    title: "EAC - Bladder accumulators",
    link: "./images/certifications/EAC-Bladder-accumulators.pdf",
  },

  {
    id: 12,
    imgSrc: "../images/EAC.svg", // Direct path to the third certification image
    title: "EAC - Piston type accumulators",
    link: "./images/certifications/EAC-Piston-type-accumulators.pdf",
  },

  {
    id: 13,
    imgSrc: "../images/EAC.svg", // Direct path to the third certification image
    title: "EAC - Nitrogen bottles",
    link: "./images/certifications/EAC-Nitrogen-bottles.pdf",
  },

  {
    id: 14,
    imgSrc: "../images/EAC.svg", // Direct path to the third certification image
    title: "EAC - Membrane accumulators",
    link: "./images/certifications/EAC-Membrane-accumulators.pdf",
  },
  {
    id: 15,
    imgSrc: "../images/crn.svg", // Direct path to the third certification image
    title: "CRN",
    link: "./images/certifications/CRN-ABSA-1.pdf",
  },
];
function Certifications() {
  const handleViewClick = (link) => {
    window.open(link, "_blank");
  };

  const handleDownloadClick = (link) => {
    const anchor = document.createElement("a");
    anchor.href = link;
    anchor.download = link.split("/").pop(); // Extracts the file name from the link
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <div>
      <div className="inner-banner">
        <div className="wrapper">
          <h1>Certifications</h1>
          <h6></h6>
        </div>
      </div>

      <div className="Certifications-sec2-bg ">
        <div className="wrapper">
          <div className="certifications-wrap">
            {certificationsData.map((certification) => (
              <div className="certifications-1" key={certification.id}>
                <div className="certifications-text-wrap">
                  <div className="certifications-text-1">
                    <img
                      src={certification.imgSrc}
                      alt={`certificationImg${certification.id}`}
                    />
                  </div>
                  <div className="certifications-text-2">
                    <h4>{certification.title}</h4>
                    <button
                      className="button-2a"
                      onClick={() => handleViewClick(certification.link)}
                    >
                      View
                    </button>

                    <button
                      className="button-2b"
                      onClick={() => handleDownloadClick(certification.link)}
                    >
                      <img src={download} alt="download icon" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <GetinTouch />
    </div>
  );
}

export default Certifications;
