import React, { useState } from "react";
import { Link } from "react-router-dom";
import GetinTouch from "./GetinTouch";
import allproducts from "./images/mi_menu.svg";
// Switches Category :
import img1 from "./images/Hand-Knob-Type.png";
import img2 from "./images/Set-Screw-Type.png";

// PressureSwitchScrewIn  category - Contains different types of PressureSwitchScrewIn  Switches
const ReliefValves = [
  {
    cat: "Set Screw Type",
    name: "Cartridge Relief Valve",
    id: "SetScrewType",
    thumbnail: img2,
  },
  {
    cat: "Hand Knob Type",
    name: "Cartridge Relief Valve",
    id: "HandKnobType",
    thumbnail: img1,
  }, //
];

// Store categories in an array for easy mapping
const allCategory = {
  label: "All",
  items: [...ReliefValves],
  heading: "Valves",
  subheading: "View Valves across all categories",
};

const categories = [
  allCategory,
  {
    label: "Relief Valves",
    items: ReliefValves,
    heading: "Relief Valves",
    subheading: "Simplex Filters for Relief Valves",
  },
];

const Valves = () => {
  const [activeTab, setActiveTab] = useState(0); // Default to the first tab
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false); // State to toggle mobile menu

  const handleTabChange = (index) => {
    setActiveTab(index); // Change the active tab
    setMobileMenuVisible(true); // Always show the menu when a tab is clicked
  };

  const renderItemsForActiveTab = () => {
    const itemsInCategory = categories[activeTab].items;
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    return (
      <div className="view-product">
        {itemsInCategory.map((item) => (
          <div className="view-product-1" key={item.id}>
            <Link to={`/valves/${item.id}`} onClick={scrollToTop}>
              <img src={item.thumbnail} alt={item.name} />
              <h6>{item.cat}</h6>
              <div>{item.name}</div>
            </Link>
          </div>
        ))}
      </div>
    );
  };

  const toggleMobileMenu = () => {
    setMobileMenuVisible((prev) => !prev); // Toggle the mobile menu visibility
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className="filter-banner"></div>
      {/* <div className="product-inner-sec1-bg"></div> */}
      <div className="wrapper">
        <div className="mobile-product-wrap">
          <div className="mobile-product-1">
            <button className="mobile-btn" onClick={toggleMobileMenu}>
              <img src={allproducts} />
              View Product Categories
            </button>
          </div>
          <div className="mobile-product-2"></div>
        </div>

        <div className="product-inner-sec1-wrap">
          <div className="product-inner-sec1-1">
            {/* Mobile Button to toggle menu */}

            {/* Mobile button container */}
            <button
              className="filter-button-1"
              onClick={() =>
                window.open("/images/Complete-Range-CR-01-001-R5.pdf", "_blank")
              }
            >
              View Full Product Range
            </button>
            <div className="product-inner-sec1-1-bg ">
              <div
                className={`mobile-btn-container ${
                  isMobileMenuVisible ? "show" : ""
                }`}
              >
                {categories.map((category, index) => (
                  <button
                    key={index}
                    onClick={() => handleTabChange(index)} // Change the tab and show the menu
                    style={{
                      backgroundColor: activeTab === index ? "#7E8CB1" : "#fff",
                      color: activeTab === index ? "#fff" : "#000",
                    }}
                  >
                    {category.label}{" "}
                    {/* Removed <a> tag for better button semantics */}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="product-inner-sec1-2">
            <div>
              <h1>{categories[activeTab].heading}</h1>
              <h3>{categories[activeTab].subheading}</h3>
            </div>
            <div>{renderItemsForActiveTab()}</div>
          </div>
        </div>
      </div>
      <GetinTouch />
    </div>
  );
};

export default Valves;
