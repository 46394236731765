import React, { useState } from "react";
import { Link } from "react-router-dom";
import GetinTouch from "./GetinTouch";

import allproducts from "./images/mi_menu.svg";
import heavyDuty from "./images/simplex/Heavy-Duty-Inline.png";
import Fabricated from "./images/simplex/Inline-Fabricated.png";
import hightPressure from "./images/simplex/Inline-High-Pressure.png";
import LowPressureEasySerice from "./images/simplex/Inline-Low-Pressure-Easy-Service.png";
import Manifold from "./images/simplex/Manifold.png";
import LowPressure from "./images/simplex/Inline-Low-Pressure.png";

//Duplex:
import LowPressureduplex from "./images/Duplex/Low-Pressure-Duplex.png";
import HighPressureduplex from "./images/Duplex/High-Pressure--Duplex.png";
import easyServiceduplex from "./images/Duplex/Low-Pressure-Easy-Service-Duplex.png";
import Fabricatedduplex from "./images/Duplex/Low-Pressure-Fabricated- Ball-Valve-Duplex.png";
import lasrgeVolumeduplex from "./images/Duplex/Low-Pressure-Large-Volume-Duplex.png";

//ReturnLine:
import tanktop from "./images/ReturnLine/Simplex-Tank-Top-Return-line.png";
import lineinline from "./images/ReturnLine/Inline-Return-line.png";
import Fabricatedreturnline from "./images/ReturnLine/Duplex-Low-Pressure-Fabricated-Return-line.png";
import returnduplextanktop from "./images/ReturnLine/Duplex-Tank-Top-Return-line.png";
import Duplexsimplexfabricated from "./images/ReturnLine/Simplex-Low-Pressure-Fabricated-Return-line.png";

//OfflineFiltrationSystem
import OfflineFiltrationSystemImg from "./images/OfflineFiltrationSystem/Offline-Filtration-System.png";

//Breather
import BreatherImg from "./images/Breather/Breather-TLF.png";
//FilterElement
import FilterElementImg from "./images/FilterElement/Filter-Element.png";
import FilterElementImg2 from "./images/FilterElement/filter-element-img-1.png";

// Define items under separate categories
const Simplex = [
  {
    cat: "Simplex",
    name: "Inline Low Pressure",
    id: "SimplexInlineLowPressure",
    thumbnail: LowPressure,
  }, // Filter for inline low pressure
  {
    cat: "Simplex",
    name: "Heavy Duty Inline",
    id: "SimplexHeavyDutyInline",
    thumbnail: heavyDuty,
  }, // Heavy duty inline filter
  {
    cat: "Simplex",
    name: "Inline Low Pressure Easy Service",
    id: "SimplexInlineLowPressureEasyService",
    thumbnail: LowPressureEasySerice,
  }, // Inline low pressure with easy service
  {
    cat: "Simplex",
    name: "Inline Low Pressure Fabricated",
    id: "SimplexInlineLowPressureFabricated",
    thumbnail: Fabricated,
  }, // Fabricated low pressure inline filter
  {
    cat: "Simplex",
    name: "Inline High Pressure",
    id: "SimplexInlineHighPressure",
    thumbnail: hightPressure,
  }, // Inline high pressure filter
  {
    cat: "Simplex",
    name: "Manifold Mounted High Pressure",
    id: "SimplexManifoldMountedHighPressure",
    thumbnail: Manifold,
  }, // Manifold mounted high pressure filter
];

const Duplex = [
  {
    cat: "Duplex",
    name: "Low Pressure",
    id: "DuplexLowPressure",
    thumbnail: LowPressureduplex,
  }, // Low pressure Duplex filter
  {
    cat: "Duplex",
    name: "High Pressure",
    id: "DuplexHighPressure",
    thumbnail: HighPressureduplex,
  }, // High pressure Duplex filter
  {
    cat: "Duplex",
    name: "Low Pressure Easy Service",
    id: "DuplexLowPressureEasyService",
    thumbnail: easyServiceduplex,
  }, // Low pressure Duplex with easy service
  {
    cat: "Duplex",
    name: "Low Pressure Fabricated - Ball Valve",
    id: "DuplexLowPressureFabricatedBallValve",
    thumbnail: Fabricatedduplex,
  }, // Fabricated low pressure with ball valve
  {
    cat: "Duplex",
    name: "Low Pressure Large Volume",
    id: "DuplexLowPressureLargeVolume ",
    thumbnail: lasrgeVolumeduplex,
  }, // Large volume low pressure Duplex filter
];

const ReturnLine = [
  {
    cat: "Return Line",
    name: "Simplex Tank Top",
    id: "ReturnLineSimplexTankTop",
    thumbnail: tanktop,
  }, // Simplex tank top filter
  {
    cat: "Return Line",
    name: "Simplex Return Line/Inline",
    id: "ReturnLineSimplexReturnLineInline",
    thumbnail: lineinline,
  }, // Simplex return line or inline filter
  {
    cat: "Return Line",
    name: "Simplex Low Pressure Fabricated",
    id: "ReturnLineSimplexLowPressureFabricated",
    thumbnail: Duplexsimplexfabricated,
  }, // Fabricated low pressure Simplex filter
  {
    cat: "Return Line",
    name: "Duplex Tank Top",
    id: "ReturnLineDuplexTankTop",
    thumbnail: returnduplextanktop,
  }, // Duplex tank top filter
  {
    cat: "Return Line",
    name: "Duplex Low Pressure Fabricated",
    id: "ReturnLineDuplexLowPressureFabricated",
    thumbnail: Fabricatedreturnline,
  }, // Fabricated low pressure Duplex filter
];

const OfflineFiltrationSystem = [
  {
    cat: "Offline Filtration System",
    name: "Offline Filtration System",
    id: "OfflineFiltrationSystem",
    thumbnail: OfflineFiltrationSystemImg,
  },
];

const Breather = [
  {
    cat: "Breather",
    name: "Breather",
    id: "BreatherTLF",
    thumbnail: BreatherImg,
  }, // Breather type TLF
];

const FilterElement = [
  {
    cat: "Filter Element",
    name: "Filter Element",
    id: "FilterElement",
    thumbnail: FilterElementImg2,
  },
  {
    cat: "Filter Element",
    name: "Cross Reference",
    id: "FilterElementCrossReference",
    thumbnail: FilterElementImg,
  },
];

// Store categories in an array for easy mapping
const allCategory = {
  label: "All",
  items: [
    ...Simplex,
    ...Duplex,
    ...ReturnLine,
    ...OfflineFiltrationSystem,
    ...Breather,
    ...FilterElement,
  ],
  heading: "Filters",
  subheading: "View Filters across all categories",
};

const categories = [
  allCategory,
  {
    label: "Simplex",
    items: Simplex,
    heading: "Simplex",
    subheading: "Simplex Filters for Inline / Manifold Mounting",
  },
  {
    label: "Duplex",
    items: Duplex,
    heading: "Duplex",
    subheading: "Duplex Filters for interruption free operation",
  },
  {
    label: "Return Line",
    items: ReturnLine,
    heading: "Return Line",
    subheading: "Tank-top Mounted Return Line Filters",
  },
  {
    label: "Offline Filtration System",
    items: OfflineFiltrationSystem,
    heading: "Offline Filtration System",
    subheading: "",
  },
  {
    label: "Breather",
    items: Breather,
    heading: "Breather",
    subheading: "",
  },
  {
    label: "Filter Element",
    items: FilterElement,
    heading: "Filter Element",
    subheading: "Star Pleated Filter Elements, Design : Out to In (Standard)",
  },
];
const FilterPage = () => {
  const [activeTab, setActiveTab] = useState(0); // Default to the first tab
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false); // State to toggle mobile menu

  const handleTabChange = (index) => {
    setActiveTab(index); // Change the active tab
    setMobileMenuVisible(true); // Always show the menu when a tab is clicked
  };

  const renderItemsForActiveTab = () => {
    const itemsInCategory = categories[activeTab].items;
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    return (
      <div className="view-product">
        {itemsInCategory.map((item) => (
          <div className="view-product-1" key={item.id}>
            <Link to={`/products/${item.id}`} onClick={scrollToTop}>
              <img src={item.thumbnail} alt={item.name} />
              <h6>{item.cat}</h6>
              <div>{item.name}</div>
            </Link>
          </div>
        ))}
      </div>
    );
  };

  const toggleMobileMenu = () => {
    setMobileMenuVisible((prev) => !prev); // Toggle the mobile menu visibility
  };

  return (
    <div>
      <div className="filter-banner">
        {/* <div className="wrapper">
          <h1>Filter</h1>
          <h6></h6>
        </div> */}
      </div>
      {/* <div className="product-inner-sec1-bg"></div> */}
      <div className="wrapper">
        <div className="mobile-product-wrap">
          <div className="mobile-product-1">
            <button className="mobile-btn" onClick={toggleMobileMenu}>
              <img src={allproducts} />
              View Product Categories
            </button>
          </div>
          <div className="mobile-product-2"></div>
        </div>

        <div className="product-inner-sec1-wrap">
          <div className="product-inner-sec1-1">
            <button
              className="filter-button-1"
              onClick={() =>
                window.open("/images/Complete-Range-CR-01-001-R5.pdf", "_blank")
              }
            >
              View Full Product Range
            </button>
            <div className="product-inner-sec1-1-bg ">
              <div
                className={`mobile-btn-container ${
                  isMobileMenuVisible ? "show" : ""
                }`}
              >
                {categories.map((category, index) => (
                  <button
                    key={index}
                    onClick={() => handleTabChange(index)} // Change the tab and show the menu
                    style={{
                      backgroundColor: activeTab === index ? "#7E8CB1" : "#fff",
                      color: activeTab === index ? "#fff" : "#000",
                    }}
                  >
                    {category.label}{" "}
                    {/* Removed <a> tag for better button semantics */}
                  </button>
                ))}
              </div>
            </div>
            {/* Mobile Button to toggle menu */}

            {/* Mobile button container */}
          </div>

          <div className="product-inner-sec1-2">
            <div>
              <h1>{categories[activeTab].heading}</h1>
              <h3>{categories[activeTab].subheading}</h3>
            </div>
            <div>{renderItemsForActiveTab()}</div>
          </div>
        </div>
      </div>
      <GetinTouch />
    </div>
  );
};

export default FilterPage;
