import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
//import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import bannerImg1 from "../images/n-banner-1.png";
import bannerThumbImg1 from "../images/banner-thumb-img-1.png";
// import bannerImg2 from "../images/n-banner-2.jpg";
// import bannerImg3 from "../images/n-banner-3.jpg";
// import "bootstrap/dist/css/bootstrap.min.css";
import HomeSlider from "../Sliders/HomeSlider";
import "./BannerAnimation.css";
function BannerAnimation() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <div className="banner-section-main">
        <div className="wrapper">
          <div className="carousel-text">
            <div className="carousel-text-1">
              <div className="carousel-text-1-1">
                <div className="banner-heading-1">
                  <h1>
                    <span>SINCE 1992</span>
                  </h1>
                </div>
                <h3>Providing Innovative Industrial Solutions</h3>
                <div className="filter-text-box">
                  Filters • Accumulators • Switches &amp; Sensors • Valves
                </div>
                <div className="clear-fix"></div>
                <Link className="button-1" to="/Products">
                  View All Products
                </Link>
              </div>
              {/* <div className="carousel-text-1-2">
                <h3>Filters • Accumlators • Switches</h3>
                <Link className="button-1" to="/Products">
                  View All Products
                </Link>
              </div> */}
            </div>
            <div className="carousel-text-2">
              <HomeSlider />
              {/* <img src={bannerThumbImg1} alt={bannerThumbImg1} /> */}
            </div>
          </div>
        </div>
      </div>

      {/* <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <img src={bannerImg1}></img>
          <Carousel.Caption>
            <div className="carousel-text">
              <div className="carousel-text-1">
                <div className="carousel-text-1-1">
                  <div className="banner-heading-1">
                    <h4>
                      <span>ESTABLISHED IN 1992</span>
                    </h4>
                  </div>
                  <h3>Providing Innovative Industrial Solutions</h3>
                </div>
                <div className="carousel-text-1-2">
                  <h3>Filters • Accumlators • Switches</h3>
                  <button className="button-1">View All Products</button>
                </div>
              </div>
              <div className="carousel-text-2">
                <img src={bannerThumbImg1} alt={bannerThumbImg1} />
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={bannerImg1}></img>
          <Carousel.Caption>
            <div className="carousel-text">
              <div className="carousel-text-1">
                <div className="carousel-text-1-1">
                  <div className="banner-heading-1">
                    <h4>
                      <span>ESTABLISHED IN 1992</span>
                    </h4>
                  </div>
                  <h3>Providing Innovative Industrial Solutions</h3>
                </div>
                <div className="carousel-text-1-2">
                  <h3>Filters • Accumlators • Switches</h3>
                  <button className="button-1">View All Products</button>
                </div>
              </div>
              <div className="carousel-text-2">
                <img src={bannerThumbImg1} alt={bannerThumbImg1} />
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={bannerImg1}></img>
          <Carousel.Caption>
            <div className="carousel-text">
              <div className="carousel-text-1">
                <div className="carousel-text-1-1">
                  <div className="banner-heading-1">
                    <h4>
                      <span>ESTABLISHED IN 1992</span>
                    </h4>
                  </div>
                  <h3>Providing Innovative Industrial Solutions</h3>
                </div>
                <div className="carousel-text-1-2">
                  <h3>Filters • Accumlators • Switches</h3>
                  <button className="button-1">View All Products</button>
                </div>
              </div>
              <div className="carousel-text-2">
                <img src={bannerThumbImg1} alt={bannerThumbImg1} />
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel> */}
    </>
  );
}

export default BannerAnimation;
