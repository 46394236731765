import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./homepage-slider.css";

const images = [
  require("../images/banner-thumb-img-1.png"),
  require("../images/banner-thumb-img-2.png"),
  require("../images/banner-thumb-img-3.png"),
  require("../images/banner-thumb-img-4.png"),
  require("../images/banner-thumb-img-5.png"),
];

const HomeSlider = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    draggable: true,
    autoplay: true,
    autoplaySpeed: 2000, // Slowed for better visibility of transitions
    arrows: false,
    dots: false,
    fade: true, // Enables fade effect
    speed: 1000, // Fade transition speed
    infinite: true,
    cssEase: "ease-in-out",
    beforeChange: (current, next) => setActiveSlide(next),
  };

  return (
    <div className="slideshow">
      <Slider {...settings}>
        {images.map((src, i) => (
          <div
            key={i}
            className={`slide ${i === activeSlide ? "zoom" : ""}`} // Apply zoom class to active slide
          >
            <img src={src} alt={`Slide ${i + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default HomeSlider;
