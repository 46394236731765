import React from "react";
import EnvironmentManagement from "./images/EnvironmentManagement-img-1.png";
import EnvironmentManagementTwo from "./images/EnvironmentManagement-img-2.png";

import GetinTouch from "./GetinTouch";

function QualityPromise() {
  return (
    <div>
      <div className="inner-banner">
        <div className="wrapper">
          <h1>EHS Policy</h1>
          <h6>Continuous Improvement Efforts</h6>
        </div>
      </div>
      <div className="our-comitments-bg ">
        <div className="wrapper">
          <div className="comitments-wrap-2">
            <h3>Managing our Ecosystem : EPE’s Core Value</h3>
            <p>
              We take pride in our role as manufacturers. Our commitment to
              excellence extends beyond the quality of our products; it
              encompasses our dedication to environmental stewardship,
              occupational health and safety, and the management of our
              ecosystem. We firmly believe that sustainable practices and a safe
              working environment are integral to our success and the well-being
              of our planet.
            </p>
            <p>
              Environmental management lies at the heart of our operations,
              guided by our ISO 14001 certification. We recognize the importance
              of minimizing our environmental footprint while maximizing our
              positive impact on the ecosystems in which we operate. From our
              manufacturing processes to our supply chain management, every
              decision we make reflects our commitment to environmental
              responsibility and the health of our ecosystems.
            </p>
            <p>
              Additionally, our ISO 45001 certification underscores our focus on
              occupational health and safety management. This internationally
              recognized standard ensures that we proactively identify and
              mitigate workplace hazards, creating a safe and healthy
              environment for our employees and partners. By integrating robust
              safety practices into our operations, we not only comply with
              regulatory requirements but also foster a culture of continuous
              improvement.
            </p>
            <p>
              At EPE, we are dedicated to managing our ecosystem effectively
              while achieving excellence in both environmental management and
              workplace safety, ensuring a sustainable future for our business
              and the communities we serve.
            </p>
          </div>
        </div>
      </div>

      <div className="wrapper">
        <div className="em-section-1-wrap">
          <div className="em-section-1-1">
            <img src={EnvironmentManagement} alt="CompanyOverviewBanner" />
          </div>
          <div className="em-section-1-2">
            <h2>ISO 14001 Certification: A Testament to Our Dedication</h2>
            <p>
              Our dedication to environmental management is not just a promise;
              it's a proven commitment. We are proud to announce that EPE is an
              ISO 14001 certified company. This prestigious certification
              reflects our adherence to the highest standards of environmental
              management.
            </p>
            <h4>What ISO 14001 Means for You</h4>
            <p>
              As our valued partners and customers, our ISO 14001 certification
              offers you several assurances:
            </p>
            <div className="list-1">
              <ol>
                <li>
                  Environmental Compliance: Our operations comply with all
                  relevant environmental regulations, ensuring that our products
                  meet the highest standards of environmental responsibility.
                </li>
                <li>
                  Continuous Improvement: ISO 14001 requires us to continually
                  assess and improve our environmental performance. By choosing
                  EPE, you can trust that we are always striving to minimize our
                  environmental impact.
                </li>
                <li>
                  Transparent Communication: We are committed to transparently
                  communicating our environmental efforts and achievements. Our
                  ISO 14001 certification serves as tangible proof of our
                  commitment to sustainability.
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div className="em-section-1-wrap">
          <div className="em-section-1-2">
            <h2>ISO 45001 Certification: Commitment to Safety and Quality</h2>
            <p>
              At EPE, we are proud to be ISO 45001 certified, a testament to our
              unwavering commitment to occupational health and safety. This
              internationally recognized standard ensures that we provide a safe
              and healthy work environment for our employees and partners.
            </p>
            <h4>What Does ISO 45001 Mean for you?</h4>

            <div className="list-1">
              <ol>
                <li>
                  Enhanced Safety Practices: Our certification demonstrates our
                  dedication to minimizing workplace hazards and risks. Clients
                  can trust that we prioritize the well-being of our workforce,
                  which in turn ensures reliable production processes.
                </li>
                <li>
                  Consistent Quality: By adhering to ISO 45001 standards, we
                  implement systematic risk management practices that lead to
                  more efficient operations. This consistency translates into
                  high-quality products and services for our clients.
                </li>
                <li>
                  Regulatory Compliance: Our commitment to meeting ISO 45001
                  standards means that we not only comply with legal
                  requirements but also strive for continuous improvement in
                  safety practices. Clients can have peace of mind knowing we
                  operate within a framework of best practices.
                </li>
                <li>
                  Reputation for Excellence: Being ISO 45001 certified
                  reinforces our reputation as a responsible and trustworthy
                  partner. Our clients can feel confident in their choice to
                  work with a company that values safety and quality.
                </li>
              </ol>
            </div>
            <p>
              At EPE, we believe that a safe workplace is a productive
              workplace. Our ISO 45001 certification reflects our commitment to
              excellence and our promise to deliver the best for our clients.
            </p>
          </div>
          <div className="em-section-1-1">
            <img src={EnvironmentManagementTwo} alt="CompanyOverviewBanner" />
          </div>
        </div>
      </div>

      <GetinTouch />
    </div>
  );
}

export default QualityPromise;
