import { Route, Routes } from "react-router-dom";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home";
import Software from "./Components/Software";
import Filter from "./Components/Filter";
import Products from "./Components/Products";
import FilterPage from "./Components/FilterPage";
import Switches from "./Components/Switches";
import Valves from "./Components/Valves";
import Accumlators from "./Components/Accumlators";
import CompanyOverview from "./Components/CompanyOverview";
import ImageDetail from "./Components/ImageDetail";
import QualityPromise from "./Components/QualityPromise";
import EnvironmentManagement from "./Components/EnvironmentManagement";
import Certifications from "./Components/Certifications";
import AwardsAchievement from "./Components/AwardsAchievement";
import Contact from "./Components/Contact";

import AppleList from "./Components/AppleList";
import AppleDescription from "./Components/AppleDescription";
import SwitchesDescription from "./Components/SwitchesDescription";
import ValvesDescription from "./Components/ValvesDescription";
import AccumlatorsDescription from "./Components/AccumlatorsDescription";
import Careers from "./Components/Careers";
import SeparateCareerDetailsTwo from "./Components/SeparateCareerDetailsTwo";
function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Software />} path="/Software" />
        <Route element={<CompanyOverview />} path="/CompanyOverview" />
        <Route element={<Contact />} path="/Contact" />
        <Route element={<Products />} path="/Products" />
        <Route element={<Filter />} path="/Filter" />
        <Route element={<ImageDetail />} path="/ImageDetail" />
        <Route element={<QualityPromise />} path="/QualityPromise" />
        <Route element={<FilterPage />} path="/FilterPage" />
        <Route element={<Switches />} path="/Switches" />
        <Route element={<Valves />} path="/Valves" />
        <Route element={<Accumlators />} path="/Accumlators" />
        <Route element={<Certifications />} path="/Certifications" />
        <Route element={<AwardsAchievement />} path="/AwardsAchievement" />
        <Route
          element={<EnvironmentManagement />}
          path="/EnvironmentManagement"
        />
        <Route path="/AppleList" element={<AppleList />} />
        {/* <Route path="/apple/:id" element={<AppleDescription />} /> */}
        <Route path="/products/:id" element={<AppleDescription />} />{" "}
        <Route path="/switches/:id" element={<SwitchesDescription />} />{" "}
        <Route path="/valves/:id" element={<ValvesDescription />} />{" "}
        <Route path="/accumlators/:id" element={<AccumlatorsDescription />} />{" "}
        {/* Updated route */}
        <Route element={<Careers />} path="/careers" />
        <Route
          path="/career-details/:id"
          element={<SeparateCareerDetailsTwo />}
        />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
