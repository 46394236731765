import React from "react";

function Footer() {
  return (
    <div>
      <div className="footer-bg">© 2024 EPE. All Rights Reserved.</div>
    </div>
  );
}

export default Footer;
