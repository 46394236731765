import React, { useState } from "react";
import { Link } from "react-router-dom";
import GetinTouch from "./GetinTouch";
import allproducts from "./images/mi_menu.svg";
// Switches Category :
import lockable from "./images/SwitchScrewIn/image-1.png";
import img2 from "./images/SwitchScrewIn/image2.png";
import img3 from "./images/SwitchScrewIn/image3.png";
import img4 from "./images/SwitchScrewIn/image4.png";
import img5 from "./images/SwitchScrewIn/image5.png";

//swithc Block
import blockimg1 from "./images/SwitchBlock/image1.png";
import blockimg2 from "./images/SwitchBlock/image2.png";
import blockimg3 from "./images/SwitchBlock/image3.png";
import blockimg4 from "./images/SwitchBlock/image4.png";

//switch Temp
import tempimg1 from "./images/SwitchTemp/image1.png";
import tempimg2 from "./images/SwitchTemp/image2.png";

// PressureSwitchScrewIn  category - Contains different types of PressureSwitchScrewIn  Switches
const PressureSwitchScrewIn = [
  {
    cat: "Pressure Switch-Screw-In",
    name: "Pressure Switch - External Setting - lockable",
    id: "PressureSwitchExternalSettinglockable",
    thumbnail: lockable,
  }, //
  {
    cat: "Pressure Switch-Screw-In",
    name: "Pressure Switch - External Setting - lockable",
    id: "PressureSwitchExternalSettinglockable2",
    thumbnail: img2,
  },
  {
    cat: "Pressure Switch-Screw-In",
    name: "Pressure Switch - Internal Setting Screw",
    id: "PressureSwitchInternalSettingScrew",
    thumbnail: img3,
  },
  {
    cat: "Pressure Switch-Screw-In",
    name: "Pressure Switch - Internal Setting Screw",
    id: "PressureSwitchInternalSettingScrew2",
    thumbnail: img4,
  },
  {
    cat: "Pressure Switch-Screw-In",
    name: "Pressure Switch - For Washing M/c",
    id: "PressureSwitchForWashingMc",
    thumbnail: img5,
  },
];

// PressureSwitchBlock  category - Contains different types of PressureSwitchBlock  Switches
const PressureSwitchBlock = [
  {
    cat: "Pressure Switch-Block",
    name: "Pressure Switch - Base mounting - Vertical",
    id: "PressureSwitchBasemountingVertical",
    thumbnail: blockimg1,
  }, //
  {
    cat: "Pressure Switch-Block",
    name: "Pressure Switch - Side mounting - Horizontal",
    id: "PressureSwitchSidemountingHorizontal",
    thumbnail: blockimg2,
  },
  {
    cat: "Pressure Switch-Block",
    name: "Pressure Switch - Base mounting",
    id: "PressureSwitchBasemounting",
    thumbnail: blockimg3,
  },
  {
    cat: "Pressure Switch-Block",
    name: "Pressure Switch - With Manometer",
    id: "PressureSwitchWithManometer",
    thumbnail: blockimg4,
  },
];

// TemperatureSwitches  category - Contains different types of TemperatureSwitches  Switches
const TemperatureSwitches = [
  {
    cat: "Temperature Switches",
    name: "Temperature Switch - Screw-in",
    id: "TemperatureSwitchScrewin",
    thumbnail: tempimg1,
  }, //
  {
    cat: "Temperature Switches",
    name: "Temperature Switch - Screw-in",
    id: "TemperatureSwitchScrewin2",
    thumbnail: tempimg2,
  },
];

// Store categories in an array for easy mapping
const allCategory = {
  label: "All",
  items: [
    ...PressureSwitchScrewIn,
    ...PressureSwitchBlock,
    ...TemperatureSwitches,
  ],
  heading: "Switches & Sensors",
  subheading: "View Switches & Sensors across all categories",
};

const categories = [
  allCategory,
  {
    label: "Pressure Switch-Screw-In",
    items: PressureSwitchScrewIn,
    heading: "Pressure Switch-Screw-In",
    subheading: "Simplex Filters for Inline / Manifold Mounting",
  },
  {
    label: "Pressure Switch-Block",
    items: PressureSwitchBlock,
    heading: "Pressure Switch-Block",
    subheading: "Duplex Filters for interruption free operation",
  },
  {
    label: "Temperature Switches",
    items: TemperatureSwitches,
    heading: "Temperature Switches",
    subheading: "Tank-top Mounted Return Line Filters",
  },
];

const Switches = () => {
  const [activeTab, setActiveTab] = useState(0); // Default to the first tab
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false); // State to toggle mobile menu

  const handleTabChange = (index) => {
    setActiveTab(index); // Change the active tab
    setMobileMenuVisible(true); // Always show the menu when a tab is clicked
  };

  const renderItemsForActiveTab = () => {
    const itemsInCategory = categories[activeTab].items;
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    return (
      <div className="view-product">
        {itemsInCategory.map((item) => (
          <div className="view-product-1" key={item.id}>
            <Link to={`/switches/${item.id}`} onClick={scrollToTop}>
              <img src={item.thumbnail} alt={item.name} />
              <h6>{item.cat}</h6>
              <div>{item.name}</div>
            </Link>
          </div>
        ))}
      </div>
    );
  };

  const toggleMobileMenu = () => {
    setMobileMenuVisible((prev) => !prev); // Toggle the mobile menu visibility
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className="filter-banner">
        {/* <div className="wrapper">
          <h1>Switches & Sensors</h1>
          <h6></h6>
        </div> */}
      </div>
      {/* <div className="product-inner-sec1-bg"></div> */}
      <div className="wrapper">
        <div className="mobile-product-wrap">
          <div className="mobile-product-1">
            <button className="mobile-btn" onClick={toggleMobileMenu}>
              <img src={allproducts} />
              View Product Categories
            </button>
          </div>
          <div className="mobile-product-2"></div>
        </div>

        <div className="product-inner-sec1-wrap">
          {/* <div className="product-inner-sec1-1">
           
            {categories.map((category, index) => (
              <button
                key={index}
                onClick={() => handleTabChange(index)}
                style={{
                  backgroundColor: activeTab === index ? "#7E8CB1" : "#fff",
                  color: activeTab === index ? "#fff" : "#000",
                }}
              >
                <a onClick={scrollToTop}>{category.label}</a>
              </button>
            ))}
          </div> */}
          <div className="product-inner-sec1-1">
            {/* Mobile Button to toggle menu */}

            {/* Mobile button container */}
            <button
              className="filter-button-1"
              onClick={() =>
                window.open("/images/Complete-Range-CR-01-001-R5.pdf", "_blank")
              }
            >
              View Full Product Range
            </button>
            <div className="product-inner-sec1-1-bg ">
              <div
                className={`mobile-btn-container ${
                  isMobileMenuVisible ? "show" : ""
                }`}
              >
                {categories.map((category, index) => (
                  <button
                    key={index}
                    onClick={() => handleTabChange(index)} // Change the tab and show the menu
                    style={{
                      backgroundColor: activeTab === index ? "#7E8CB1" : "#fff",
                      color: activeTab === index ? "#fff" : "#000",
                    }}
                  >
                    {category.label}{" "}
                    {/* Removed <a> tag for better button semantics */}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="product-inner-sec1-2">
            <div>
              <h1>{categories[activeTab].heading}</h1>
              <h3>{categories[activeTab].subheading}</h3>
            </div>
            <div>{renderItemsForActiveTab()}</div>
          </div>
        </div>
      </div>
      <GetinTouch />
    </div>
  );
};

export default Switches;
