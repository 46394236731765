import React, { useState } from "react";
import { Link } from "react-router-dom";

// Define items under separate categories
const category1 = [
  { name: "Inline Low Pressure", id: 1, thumbnail: "./images/product-1.png" },
  { name: "Inline Low Pressure", id: 2, thumbnail: "./images/product-1.png" },
  { name: "Inline Low Pressure", id: 3, thumbnail: "./images/product-1.png" },
  { name: "Inline Low Pressure", id: 55, thumbnail: "./images/product-1.png" },
  { name: "Inline Low Pressure", id: 66, thumbnail: "./images/product-1.png" },
];

const category2 = [
  { name: "Apple 4", id: 4, thumbnail: "apple4_thumb.jpg" },
  { name: "Apple 5", id: 5, thumbnail: "apple5_thumb.jpg" },
  { name: "Apple 6", id: 6, thumbnail: "apple6_thumb.jpg" },
];

const category3 = [
  { name: "Apple 7", id: 7, thumbnail: "apple7_thumb.jpg" },
  { name: "Apple 8", id: 8, thumbnail: "apple8_thumb.jpg" },
  { name: "Apple 9", id: 9, thumbnail: "apple9_thumb.jpg" },
  { name: "Apple 10", id: 10, thumbnail: "apple10_thumb.jpg" },
];

// Store categories in an array for easy mapping
const allCategory = {
  label: "All",
  items: [...category1, ...category2, ...category3],
  heading: "All Products",
  subheading: "View all products across all categories",
};

const categories = [
  allCategory,
  {
    label: "Category 1",
    items: category1,
    heading: "Simplex",
    subheading: "Simplex Filters for Inline / Manifold Mounting",
  },
  {
    label: "Category 2",
    items: category2,
    heading: "This is Category 2",
    subheading: "Apple 4, Apple 5, and Apple 6",
  },
  {
    label: "Category 3",
    items: category3,
    heading: "This is Category 3",
    subheading: "Apple 7, Apple 8, Apple 9, and Apple 10",
  },
];

const AppleList = () => {
  const [activeTab, setActiveTab] = useState(0); // Default to the first tab

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const renderItemsForActiveTab = () => {
    const itemsInCategory = categories[activeTab].items;

    return (
      <div className="view-product">
        {itemsInCategory.map((item) => (
          <div className="view-product-1" key={item.id}>
            <Link to={`/products/${item.id}`}>
              <img src={item.thumbnail} alt={item.name} />
              <div>{item.name}</div>
            </Link>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <div className="inner-banner">
        <div className="wrapper">
          <h1>Filter</h1>
          <h6></h6>
        </div>
      </div>
      <div className="product-inner-sec1-bg"></div>
      <div className="wrapper">
        <div className="product-inner-sec1-wrap">
          <div className="product-inner-sec1-1">
            {/* Tabs with "All" tab first */}
            {categories.map((category, index) => (
              <button
                key={index}
                onClick={() => handleTabChange(index)}
                style={{
                  backgroundColor: activeTab === index ? "#7E8CB1" : "#fff",
                  color: activeTab === index ? "#fff" : "#000",
                }}
              >
                {category.label}
              </button>
            ))}
          </div>
          <div className="product-inner-sec1-2">
            <div>
              <h2>{categories[activeTab].heading}</h2>
              <h3>{categories[activeTab].subheading}</h3>
            </div>
            <div>{renderItemsForActiveTab()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppleList;
