import React from "react";
import EnvironmentManagement from "./images/EnvironmentManagement-img-1.png";

import GetinTouch from "./GetinTouch";

function Software() {
  return (
    <div>
      <div className="inner-banner">
        <div className="wrapper">
          <h1>Software</h1>
          <h6>Will be updated soon..</h6>
        </div>
      </div>
      <div className="our-comitments-bg ">
        <div className="wrapper">
          <div className="comitments-wrap-2">
            <p>Will be updated soon..</p>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </div>
      </div>

      <GetinTouch />
    </div>
  );
}

export default Software;
