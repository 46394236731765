import React, { useState } from "react";
import { Link } from "react-router-dom";
import GetinTouch from "./GetinTouch";
import allproducts from "./images/mi_menu.svg";

// Accumulators Category
import standerd from "./images/AccBlader/Standard.png";
import ASME from "./images/AccBlader/ASME.png";
import LargeVolume from "./images/AccBlader/LargeVolume.png";

//Membrane -images
import Rechargable from "./images/Accmembarine/Rechargable.png";
import Sealed from "./images/Accmembarine/Sealed.png";
import Screwed from "./images/Accmembarine/Screwed.png";

//piston -imgaes
import Standard from "./images/AccPiston/Standard.png";
import Crimped from "./images/AccPiston/Crimped.png";

//Accessories - images
import SafetyBlock from "./images/AccAccessories/Safety.png";
import ChargingKit from "./images/AccAccessories/Charging.png";
import ChargingKitHighPressure from "./images/AccAccessories/ChargingKitHoghPressure.png";
import ChargingKit2 from "./images/AccAccessories/ChargingKit.png";
import BackupBottles from "./images/AccAccessories/BackupBottles.png";
import ChargingBlock from "./images/AccAccessories/ChargingBlock.png";
import MountingAccessories from "./images/AccAccessories/MountingAccessories.png";
import PistonAccumulator from "./images/AccAccessories/pistonAccumulator.png";

//OperatorManuals -images
// om -images
import om2 from "./images/om-2.png";
import om3 from "./images/om-3.png";

// Bladder  category - Contains different types of Bladder  Accumulators
const Bladder = [
  { cat: "Bladder", name: "Standard", id: "Standard", thumbnail: standerd }, //
  {
    cat: "Bladder",
    name: "ASME Design",
    id: "ASMEDesign",
    thumbnail: ASME,
  },
  {
    cat: "Bladder",
    name: "Large Volume ",
    id: "LargeVolume",
    thumbnail: LargeVolume,
  },
];

// Membrane   category - Contains different types of Membrane   Accumulators
const Membrane = [
  {
    cat: "Membrane",
    name: "Rechargable ",
    id: "Rechargable",
    thumbnail: Rechargable,
  }, //
  {
    cat: "Membrane",
    name: "Sealed (Non Rechargable)",
    id: "SealedNonRechargable",
    thumbnail: Sealed,
  },
  {
    cat: "Membrane",
    name: "Screwed (Repairable)",
    id: "ScrewedRepairable",
    thumbnail: Screwed,
  },
];

// Piston    category - Contains different types of Piston    Accumulators
const Piston = [
  {
    cat: "Piston",
    name: "Standard Repairable ",
    id: "StandardRepairable ",
    thumbnail: Standard,
  }, //
  {
    cat: "Piston",
    name: "Crimped Non-Repairable",
    id: "CrimpedNonRepairable",
    thumbnail: Crimped,
  },
];

// Accessories     category - Contains different types of Piston    Accumulators
const Accessories = [
  {
    cat: "Accessories",
    name: "Safety Block",
    id: "SafetyBlock",
    thumbnail: SafetyBlock,
  }, //
  {
    cat: "Accessories",
    name: "Charging Kit",
    id: "ChargingKit",
    thumbnail: ChargingKit,
  },
  {
    cat: "Accessories",
    name: "Charging Kit – High Pressure",
    id: "ChargingKitHighPressure",
    thumbnail: ChargingKitHighPressure,
  },
  {
    cat: "Accessories",
    name: "Charging Kit",
    id: "ChargingKit2",
    thumbnail: ChargingKit2,
  },
  {
    cat: "Accessories",
    name: "Backup Bottles ",
    id: "BackupBottles",
    thumbnail: BackupBottles,
  },
  {
    cat: "Accessories",
    name: "Charging Block",
    id: "ChargingBlock",
    thumbnail: ChargingBlock,
  },
  {
    cat: "Accessories",
    name: "Mounting Accessories ",
    id: "MountingAccessories",
    thumbnail: MountingAccessories,
  },
  {
    cat: "Accessories",
    name: "Piston Accumulator – Limit Switching Device ",
    id: "PistonAccumulatorLimitSwitchingDevice",
    thumbnail: PistonAccumulator,
  },
];

// Operator Manuals   category - Contains different types of Operator Manuals   Accumulators
const OperatorManuals = [
  {
    cat: "Operator Manuals",
    name: "Bladder Accumulator ",
    id: "BladderAccumulator ",
    thumbnail: standerd,
  }, //
  {
    cat: "Operator Manuals",
    name: "Diaphragm Accumulator",
    id: "DiaphragmAccumulator",
    thumbnail: om2,
  },
  {
    cat: "Operator Manuals",
    name: "Piston Accumulator",
    id: "PistonAccumulator",
    thumbnail: om3,
  },
];

// Store categories in an array for easy mapping
const allCategory = {
  label: "All",
  items: [
    ...Bladder,
    ...Membrane,
    ...Piston,
    ...Accessories,
    ...OperatorManuals,
  ],
  heading: "Accumulators",
  subheading: "View Accumulators across all categories",
};

const categories = [
  allCategory,
  {
    label: "Bladder",
    items: Bladder,
    heading: "Bladder",
    subheading: "Simplex Filters for Inline / Manifold Mounting",
  },
  {
    label: "Membrane",
    items: Membrane,
    heading: "Membrane",
    subheading: "Duplex Filters for interruption free operation",
  },
  {
    label: "Piston",
    items: Piston,
    heading: "Piston",
    subheading: "Tank-top Mounted Return Line Filters",
  },
  {
    label: "Accessories",
    items: Accessories,
    heading: "Accessories",
    subheading: "Duplex Filters for interruption free operation",
  },
  {
    label: "Operator Manuals",
    items: OperatorManuals,
    heading: "Operator Manuals",
    subheading: "",
  },
];

const Accumlators = () => {
  const [activeTab, setActiveTab] = useState(0); // Default to the first tab
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false); // State to toggle mobile menu

  const handleTabChange = (index) => {
    setActiveTab(index); // Change the active tab
    setMobileMenuVisible(true); // Always show the menu when a tab is clicked
  };

  const renderItemsForActiveTab = () => {
    const itemsInCategory = categories[activeTab].items;
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    return (
      <div className="view-product">
        {itemsInCategory.map((item) => (
          <div className="view-product-1" key={item.id}>
            <Link to={`/accumlators/${item.id}`} onClick={scrollToTop}>
              <img src={item.thumbnail} alt={item.name} />
              <h6>{item.cat}</h6>
              <div>{item.name}</div>
            </Link>
          </div>
        ))}
      </div>
    );
  };
  const toggleMobileMenu = () => {
    setMobileMenuVisible((prev) => !prev); // Toggle the mobile menu visibility
  };
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className="filter-banner">
        {/* <div className="wrapper">
          <h1>Accumulators</h1>
          <h6></h6>
        </div> */}
      </div>
      {/* <div className="product-inner-sec1-bg"></div> */}
      <div className="wrapper">
        <div className="mobile-product-wrap">
          <div className="mobile-product-1">
            <button className="mobile-btn" onClick={toggleMobileMenu}>
              <img src={allproducts} />
              View Product Categories
            </button>
          </div>
          <div className="mobile-product-2"></div>
        </div>

        {/* <div className="full-1">
          <button className="button-2">View Full Products Range</button>
        </div> */}
        <div className="product-inner-sec1-wrap">
          <div className="product-inner-sec1-1">
            {/* Mobile Button to toggle menu */}

            {/* Mobile button container */}
            <button
              className="filter-button-1"
              onClick={() =>
                window.open("/images/Complete-Range-CR-01-001-R5.pdf", "_blank")
              }
            >
              View Full Product Range
            </button>
            <div className="product-inner-sec1-1-bg ">
              <div
                className={`mobile-btn-container ${
                  isMobileMenuVisible ? "show" : ""
                }`}
              >
                {categories.map((category, index) => (
                  <button
                    key={index}
                    onClick={() => handleTabChange(index)} // Change the tab and show the menu
                    style={{
                      backgroundColor: activeTab === index ? "#7E8CB1" : "#fff",
                      color: activeTab === index ? "#fff" : "#000",
                    }}
                  >
                    {category.label}{" "}
                    {/* Removed <a> tag for better button semantics */}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="product-inner-sec1-2">
            <div>
              <h1>{categories[activeTab].heading}</h1>
              <h3>{categories[activeTab].subheading}</h3>
            </div>
            <div>{renderItemsForActiveTab()}</div>
          </div>
        </div>
      </div>
      <GetinTouch />
    </div>
  );
};

export default Accumlators;
