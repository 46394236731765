import React from "react";
import GetinTouch from "./GetinTouch";
import { Link } from "react-router-dom";


const certificationsData = [
  {
    id: 1,
    imgSrc: "../images/Filter-Icon.png", // Direct path to the first certification image
    title: "Filters",
    content:
      "Hydraulic Filters in Inline, Pressure Line, Return Line in Simplex, Duplex, Manifold Mounted and Skid Mounted designs, Filter Elements and spares for EPE and various other makes.",
    link: "/FilterPage",
  },
  {
    id: 2,
    imgSrc: "../images/Accumlators-icon.png", // Direct path to the second certification image
    title: "Accumulators",
    content:
      "Hydraulic Accumulators in Bladder, Diaphragm / Membrane (Welded & Screwed) and Piston types",
    link: "/Accumlators",
  },
  {
    id: 3,
    imgSrc: "../images/Switches-icon.png", // Direct path to the third certification image
    title: "Switches & Sensors",
    content: "Pressure/ temperature switches and sensors",
    link: "/Switches",
  },
  {
    id: 4,
    imgSrc : "../images/valves-logo-icon.png",
    title: "Valves",
    content:
      "Cartridge type screw-in relief valves in set-srew and hand-knob versions",
    link: "/Valves",
  },
];
function Products() {
  const handleViewClick = (link) => {
    window.open(link, "_self");
  };

  const handleDownloadClick = (link) => {
    const anchor = document.createElement("a");
    anchor.href = link;
    anchor.download = link.split("/").pop(); // Extracts the file name from the link
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <div>
      <div className="inner-banner">
        <div className="wrapper">
          {/* <h1 className="product-heading-1">Products</h1> */}
          {/* <h1>Products</h1>
          <h6>Continuous Improvement Efforts</h6> */}
          <div className="Products-text-wrap no-bg">
            {certificationsData.map((certification) => (
              <div className="Products-text-1" key={certification.id}>
                <Link onClick={() => handleViewClick(certification.link)}>
                  <div className="Products-text-1-img">
                    <img
                      src={certification.imgSrc}
                      // alt={`certificationImg${certification.id}`}
                      alt={certification.title}
                    />
                  </div>
                  <h4>{certification.title}</h4>
                  <p>{certification.content}</p>
                </Link>

                {/* <div className="Products-text-wrap">
                  <div className="Products-text-1">
                   
                  </div>
                  <div className="Products-text-2">
                    
                  </div>
                </div> */}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="Products-sec2-bg ">
        <div className="wrapper"></div>
      </div>

      <GetinTouch />
    </div>
  );
}

export default Products;
