import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import productImg1 from "./images/product-img-1.png";
import GetinTouch from "./GetinTouch";

//switches categories
import ScrewInpdf1 from "./images/SwitchScrewIn/PST1Rev.pdf";
import ScrewInpdf2 from "./images/SwitchScrewIn/PST2.pdf";
import ScrewInpdf3 from "./images/SwitchScrewIn/PST3Rev.pdf";
import ScrewInpdf4 from "./images/SwitchScrewIn/PSTMRev.pdf";
import ScrewInpdf5 from "./images/SwitchScrewIn/PSTY.pdf";

//screIn Images
import lockable from "./images/Set-Screw-Type-img-1.png";
import img2 from "./images/Hand-Knob-Type-img-1.png";
import img3 from "./images/SwitchScrewIn/image3.png";
import img4 from "./images/SwitchScrewIn/image4.png";
import img5 from "./images/SwitchScrewIn/image5.png";

// -----------
// Switch : Block
import blockpdf1 from "./images/SwitchBlock/PSB1.pdf";
import blockpdf2 from "./images/SwitchBlock/PSB2.pdf";
import blockpdf3 from "./images/SwitchBlock/PSB3.pdf";
import blockpdf4 from "./images/SwitchBlock/PSVMRev.pdf";
// import ScrewInpdf5 from "./images/SwitchScrewIn/PSTY.pdf"
//swithc Block imges
import blockimg1 from "./images/SwitchBlock/image1.png";
import blockimg2 from "./images/SwitchBlock/image2.png";
import blockimg3 from "./images/SwitchBlock/image3.png";
import blockimg4 from "./images/SwitchBlock/image4.png";

// ----------
// switch : temp pdfs
import tempdf1 from "./images/SwitchTemp/TST1Rev.pdf";
import temppdf2 from "./images/SwitchTemp/TST2.pdf";
//switch Temp
//switch Temp
import tempimg1 from "./images/SwitchTemp/image1.png";
import tempimg2 from "./images/SwitchTemp/image2.png";

const itemDescriptions = {
  SetScrewType: {
    name: "Set Screw Type - Cartridge Relief Valve",
    description:
      "PRVS type pressure relief valves are direct-acting poppet type safety valves. These valve are screw-in cartridge type & designed to prevent over-pressure in hydraulic system",
    bannerImage: lockable,
    pdf: ScrewInpdf1,
    details: {
      TechnicalData: [
        "Design: poppet seat cartridge valve Set Screw Type",
        "Nominal Size: 10mm[NG10]",
        "Flow Capacity : 140 lpm, (37 gpm)",
        // "Application: Suitable for low-pressure systems",
        "Max.Pressure (inlet) : 630 bar [9150 psi]",
        "Max.Pressure (outlet) : 315 bar [4568 psi]",
        "Test Pressure (PT) : 1.43 x PS (as per CE/PED)",
        "Temperature range :  -20 to +80 C (Standard) , -4 F to +176 F (Standard)",
        "Port Cavity : M35 x 1.5",
        "Type: PRVS",
      ],
    },
  },
  HandKnobType: {
    name: "Hand Knob Type - Cartridge Relief Valve",
    description:
      "PRVK type pressure relief valves are direct-acting poppet type safety valves. These valve are screw-in cartridge type & designed to prevent over-pressure in hydraulic system",
    bannerImage: img2, //
    pdf: ScrewInpdf2, //
    details: {
      TechnicalData: [
        "Design: poppet seat cartridge valve Hand Knob Type",
        "Nominal Size: 10mm[NG10]",
        "Flow Capacity : 140 lpm, (37 gpm)",
        // "Application: Suitable for low-pressure systems",
        "Max.Pressure (inlet) : 630 bar [9150 psi]",
        "Max.Pressure (outlet) : 315 bar [4568 psi]",
        "Test Pressure (PT) : 1.43 x PS (as per CE/PED)",
        "Temperature range :  -20 to +80 C (Standard) , -4 F to +176 F (Standard)",
        "Port Cavity : M35 x 1.5",
        "Type: PRVK",
      ],
    },
  },
  PressureSwitchInternalSettingScrew: {
    name: "Pressure Switch - Internal Setting Screw",
    description:
      "PST3 Series Pressure Switch opens and closes electrical circuit depending on pressure increase or decrease",
    bannerImage: img3, //
    pdf: ScrewInpdf3, //
    details: {
      TechnicalData: [
        "Mechanical Connection: : 1/8” BSP, 1/4” BSP, 1/8”’ BSPT, 1/4”’ BSPT , M10x1, M12x1.5, 1/8'' NPT, 1/4'' NPT.",
        "Reproducibility : ±3% of adjusted pressure",
        "Working Temperature  : -40°C ...+85°C [-40°F ...+185°F]",
        "Vibration Test (DIN EN 60068-2-27) : 20g (Test Time 30 min)",
        "Shock Test (DIN EN 60068-2-27:1993) :  30g",
        "Working Cycle:   5,000,000 cycles",
        "Viscosity :   Between 10 ... 800 mm /sec",
        "Electrical Connection Feature   :   250 VAC 5A",
        "Type : PST3",
      ],
    },
  },
};

const ValvesDescription = () => {
  const { id } = useParams(); // Get the item id from the URL
  const item = itemDescriptions[id]; // Get the specific item's data
  const navigate = useNavigate(); // Hook for navigation

  if (!item) {
    return <div>Item not found</div>;
  }

  const handleEnquiryClick = () => {
    navigate("/Contact"); // Redirect to the ContactUs page
  };

  return (
    <div>
      <div className="product-dis-banner">
        <div className="wrapper">
          <div className="product-dis-content">
            <div className="product-dis-wrap">
              <div className="product-dis-1">
                <img src={item.bannerImage} alt={item.name} />
              </div>
              <div className="product-dis-2">
                <h1>{item.name}</h1>
                <h6>{item.description}</h6>
                <div style={{ marginTop: "20px" }}>
                  <a
                    href={item.pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button-3"
                  >
                    View PDF
                  </a>
                  <button
                    onClick={handleEnquiryClick}
                    className="button-2 m-l-1"
                  >
                    Enquire Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Description Section */}
      <div className="product-i-sec1-bg">
        <div className="wrapper">
          <div className="product-i-sec1-wrap">
            <div className="product-i-sec1-1">
              <h3>TECHNICAL DATA</h3>
              <ul>
                {item.details.TechnicalData.map((detail, index) => (
                  <li key={index}>{detail}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <GetinTouch />
    </div>
  );
};

export default ValvesDescription;
